@use "../base/" as *;

.left-side {
    display: none;
    // grid-area: left-side;
    display: flex;
    flex-direction: column;

    h2 {
        // @extend .underline;
        text-align: center;
        // margin: 0 auto;
    }
}

.left-side__posts {
    display: flex;
    flex-direction: column;
    max-height: 150px;
    overflow-y: scroll;
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .left-side {
        grid-area: left-side;
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        max-height: 720px;
        overflow-y: scroll;
    }
}
