@use "../base/" as *;

.home {
    @extend .main-content;
    @extend .main-container;
    @extend .main-container__style;
    padding: 1rem;
}

// .home__old {
//     @extend .main-container;

//     display: grid;
//     grid-area: main;
//     grid-template-columns: repeat(6, 1fr);
//     align-self: start;

//     img {
//         width: 100%;
//         object-fit: contain;
//     }
// }
// .home__intro,
// .home__bg {
//     grid-row: 1;
//     align-self: center;
// }

// .home__bg {
//     grid-column: 1/7;
// }

// .home__intro {
//     // height: 60%;
//     display: grid;
//     grid-column: 2/6;
//     grid-template-rows: repeat(2, fr);
//     gap: 1rem;
//     background-color: rgba(0, 0, 0, 0.5);
//     box-shadow: 0 0 20px 20px rgba(0, 0, 0, 0.5);

//     p {
//         color: white;
//         font-style: italic;
//         color: rgb(201, 197, 197);
//     }
// }

// .home__header {
//     display: grid;
//     align-content: center;
//     justify-content: center;
// }

// .home__quote {
//     color: rgb(201, 197, 197);
//     font-size: 2rem;
// }
