// @supports (grid-template-rows: masonry) {
.masonry {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: masonry;
    break-inside: avoid;
    padding: 1rem;
}
// }
.masonry__item {
    padding: 1.5em;
    background: white;
    box-shadow: 0 0 3em rgba(0, 0, 0, 0.15);

    img {
        max-width: 100%;
    }
}

@media screen and (min-width: 480px) {
    .masonry {
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: masonry;
        break-inside: avoid;
    }
}
