@use "../base/" as *;

.main__blog {
    @extend .main-content;
    @extend .main-container;
    display: grid;
    grid-template-areas: "blog-header" "blog-articles";
    // padding: 2rem;
    background: white;
    box-shadow: 0 0 3em rgba(0, 0, 0, 0.15);
}

.main__blog-header {
    padding: 1rem 1rem 0 1rem;
    display: grid;
    grid-area: blog-header;
    h1 {
        text-align: center;
        text-decoration-color: map-get($colors, "secondary");
        margin-bottom: 0.5rem;
        font-size: 2rem;
    }
}

.main__blog-image {
    display: grid;
    // grid-template-columns: repeat(3, 1fr);

    img,
    .main_blog-image-text {
        grid-row: 1;
    }

    img {
        width: 100%;
        height: auto;
        grid-column: 1/-1;
        border-radius: 10px;
    }
    .main_blog-image-text {
        grid-column: 1/-2;
        align-self: center;
        color: white;
        // background-color: rgba(245, 245, 245, 0.65);
        background-color: rgba(0, 0, 0, 0.5);
        padding: 1rem;
        filter: drop-shadow(30px 20px 20px #000);
        border-radius: 0px 17px 17px 0px;
        -webkit-border-radius: 0px 17px 17px 0px;
        -moz-border-radius: 0px 17px 17px 0px;
    }
    p {
        font-style: italic;
    }
}

.main__blog-articles {
    display: grid;
    gap: 1rem;

    // grid-area: blog-articles;
    padding: 0 1rem 1rem 1rem;

    article {
        display: grid;
        // grid-template-columns: 1fr;

        h2,
        h3 {
            margin-bottom: 0;
            margin-top: 0;
        }
        p {
            margin-bottom: 0.5rem;
            font-style: normal;
        }
    }
}

.main__blog-aside {
    display: grid;
    grid-template-columns: repeat(3, auto);
    // grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    gap: 1rem;

    a {
        @extend .inlineATag;
        font-size: 48px;
        text-decoration: none;
        color: map-get($colors, "secondary");
    }

    img {
        max-width: 100%;
        height: auto;
        object-fit: contain;
    }
}
.affiliate__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
