@use "../base/" as *;

.header {
    display: grid;
    // gap: 1.5rem;
    grid-area: header;
    height: 200px;
    grid-template-columns: 1fr;
    grid-template-areas:
        "logo"
        "nav";
    // padding-top: 1.5rem;
    margin-top: 1.5rem;
}
.header__logo {
    display: grid;
    grid-area: logo;
    justify-content: center;

    // display: flex;
    // align-items: center;
    // justify-content: center;
}

.header__nav {
    display: grid;
    grid-area: nav;
    justify-content: center;
    .nav {
        a {
            font-size: map-get($font-size, "l") !important;
        }
    }
}

.header__options {
    grid-area: options;
}
