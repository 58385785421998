@use "../base/" as *;

.tools {
    @extend .main-content;
    @extend .main-container;
    display: grid;
    grid-gap: 1rem;
}

.tools__description {
    text-align: center;
}

.tools__oilPercent {
    @extend .main-container__style;
    padding: 1rem;
}

.tools__kwh-price {
    @extend .main-container__style;
    padding: 1rem;
}
.kwh-price__range {
    padding: 1rem 10px 1rem 0;
}

.tools__intrest {
    @extend .main-container__style;
    padding: 1rem;
}

.tool__component {
    display: grid;

    // box-sizing: border-box !important;
}

.tools__description {
    padding: 1rem;
}
