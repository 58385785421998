@use "../base/" as *;

.addBlog__intro {
    display: grid;
    margin-bottom: 4rem;
}
.addBlog__content {
    display: grid;
    margin-bottom: 4rem;
}

.addBlog__content-affiliate {
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.blog-add-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 2000px;
    margin: 0 auto;
    column-gap: 1rem;
}
.blog-add__form {
    // padding: 1rem;
    // max-width: 2000px;
    // margin: 0 auto;
}
.blog-add__raw {
    pre[data-lang]::before {
        content: attr(data-lang);
        display: block;
    }
    pre code {
        background-color: #eee;
        border: 1px solid #999;
        display: block;
        padding: 20px;
    }
    code {
        font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter",
            "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New",
            Courier, monospace;
    }
    pre {
        word-wrap: break-word;
        white-space: pre-wrap;
    }
}
