// @import url("https://fonts.googleapis.com/css2?family=Alegreya+SC:ital,wght@0,400;0,500;0,700;0,800;0,900;1,400;1,500;1,700;1,800;1,900&display=swap");

// @import url("https://fonts.googleapis.com/css2?family=Tangerine:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

$colors: (
    "primary": #398378,
    "secondary": #833944,
    "linkColor": #3a61cc,
);

$font-size: (
    "xs": 0.5rem,
    "s": 0.85rem,
    "m": 1rem,
    "l": 1.25rem,
    "xl": 1.5rem,
);

h1,
.h1 {
    font-size: map-get($font-size, "xl");
    font-family: "Alegreya", serif;
    color: map-get($colors, "primary");
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 1rem;
    margin-top: 0;
}
h2,
.h2 {
    font-size: map-get($font-size, "xl");
    font-family: "Alegreya", serif;
    color: map-get($colors, "primary");
    font-weight: 700;
    line-height: 1.5;
    // margin-bottom: 1rem;
    margin-bottom: 0;
    margin-top: 0;
}
h3,
.h3 {
    font-family: "Roboto", sans-serif;
    font-size: map-get($font-size, "s");
    font-weight: 700;
    line-height: 1.5;
    // margin-bottom: 1rem;
    margin-bottom: 0;
    margin-top: 0;

    letter-spacing: 0.25em; /* Fallback if the `cap` unit isn't supported  */
    text-transform: uppercase;
}
.h3__primary {
    color: map-get($colors, "primary");
    text-decoration: underline;
}
h4,
.h4 {
    font-size: map-get($font-size, "s");
    font-family: "Alegreya", serif;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 1rem;
    margin-top: 0;
}
h5,
.h5 {
    font-size: map-get($font-size, "xs");
    font-family: "Alegreya", serif;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 1rem;
    margin-top: 0;
}

p,
.p {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    margin-top: 0.5rem;
    font-size: map-get($font-size, "m");
    letter-spacing: 0.1em;
    line-height: 1.5;

    a {
        font-family: "Roboto", sans-serif;
        font-weight: 700;
        margin-top: 0.5rem;
        font-size: map-get($font-size, "m");
        letter-spacing: 0.1em;
        line-height: 1.5;
        text-decoration: underline;
        text-decoration-color: map-get($colors, "secondary");
        text-underline-offset: 0.3rem;
        text-decoration-thickness: 0.5px;
    }
}

a,
.a {
    // font-size: map-get($font-size, "m");
    font-family: "Alegreya", serif;
    color: map-get($colors, "primary");
    font-weight: 700;
    letter-spacing: 0.12em;
    line-height: 1.5;
    margin-bottom: 1rem;
    margin-top: 0;
    text-decoration: none;
    // font-size: 48px;
}

.affiliate-link {
    color: map-get($colors, "linkColor");
}

.affiliate-link:hover {
    text-decoration: underline;
    cursor: pointer;
}

code {
    font-family: monospace;
    color: black;
    background: rgba(255, 251, 1, 0.719);
    letter-spacing: 0;
    padding: 0 0.5rem 0 0.5rem;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
ol {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
