@use "../base/" as *;
.footer {
    display: grid;
    grid-area: footer;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    justify-items: center;
    padding: 1rem;

    // grid-row-end: -1;

    // bottom: 0;

    ul {
        list-style-type: none;
        // margin: 0;
        // padding: 0;
        li {
            margin-bottom: 8px;
        }
        a {
            letter-spacing: 0.15em;
        }
    }
}

.footer__contact {
}
.footer__tribute {
    display: grid;
    justify-content: center;
    justify-items: center;
    p {
        text-align: center;
        font-size: 0.6em;
    }
}
.footer__sitemap {
}
