@use "./typography.scss" as *;

.underline {
    text-decoration: underline !important;
    text-decoration-color: map-get($colors, "primary") !important;
    text-underline-offset: 0.5rem !important;
    text-decoration-thickness: 2px !important;
}

.inlineATag {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    margin-top: 0.5rem;
    font-size: map-get($font-size, "m");
    letter-spacing: 0.1em;
    line-height: 1.5;
    text-decoration: underline;
    text-decoration-color: map-get($colors, "secondary");
    text-underline-offset: 0.3rem;
    text-decoration-thickness: 0.5px;
}

.form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
}

.form__group {
    display: grid;
    grid-template-columns: 1fr;
}

.form__label {
    display: grid;
    font-family: "Roboto", sans-serif;
    font-size: 1em;
    // margin-top: 1rem;
}

.form__input {
    font-family: "Roboto", sans-serif;
    color: #333;
    height: 48px;
    padding: 5px;
    border-radius: 0.2rem;
    border: 2px solid map-get($colors, "primary");
    // border-color: map-get($colors, "primary");
    // background-color: rgb(255, 255, 255);
}

.form__button {
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0.1em;
    line-height: 1.5;
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
    background-color: map-get($colors, "primary");
    padding: 1rem 2rem;
    border-radius: 0.2rem;
    border: none;
    margin-top: 1rem;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
        background-color: hsl(150, 36%, 33%);
    }
}

.form__button-small {
    @extend .form__button;
    padding: 0.5rem 0.8rem;
    max-height: 48px;
}
