.blog-preview {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5rem;
}

.blog-preview__meta {
    font-size: smaller;
    color: black;
    margin: 0;
}

.blog-preview__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.blog-preview__title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    letter-spacing: normal;
    margin: 0;
}
.blog-preview__description {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
    letter-spacing: 0.1em;
}
