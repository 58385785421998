* {
    box-sizing: border-box;
}

body {
    margin: 0;
    background-color: rgb(236, 235, 225);
    height: 100vh;
}

.open-garden {
    display: grid;
    // grid-template-columns: 1fr;
    grid-template-areas:
        "header"
        "main"
        "footer";
    grid-row-gap: 1rem;
    grid-template-rows: auto 1fr auto;
    min-height: 100vh;
    min-height: 100dvh;
}

.container {
    display: grid;
    grid-template-rows: repeat(2, auto);
    // min-height: 100vh;
}
.section__default {
    display: grid;
    justify-items: center;
}
