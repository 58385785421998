@use "./typography.scss" as *;

.main-content {
    grid-area: main;
    // margin: auto 1rem auto 1rem;
    margin: auto;
}

.main-container {
    max-width: 60rem;
}

.main-container__style {
    background: white;
    box-shadow: 0 0 3em rgba(0, 0, 0, 0.15);
}

fieldset > legend {
    @extend h3;
}
